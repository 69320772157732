/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Main nav
~~~~~~~~~~~~~~~~~~~~~~~~~ */
.globalNav {
	margin: $space-none;
	&__menu {
		list-style: none;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		padding: 0;
		margin: 0;
		li {
			margin: 0;
			z-index: 0;
			position: relative;
			display: flex;
			flex-flow: row nowrap;
			transition: all 1s ease;
			[aria-label]:after {
				display: none;
				@media (hover: none) and (pointer: coarse) {
					display: none;
				}
				@include respond-to("small") {
					color: rgba($color--primary,0.5);
					content: attr(aria-label);
					display: none;
					font-size: $font-s;
					overflow: visible;
					padding: $space-none;
					border-radius: 2px;
					pointer-events: none;
					text-decoration: none;
					text-indent: 0;
					white-space: nowrap;
					text-align: center;
					opacity: 0;
					transition: all .4s ease;
				}
			}
	
			[aria-label]:hover:after,
			[aria-label]:focus:after {
				display: inline-block;
				opacity: 1;
			}
			&.item--active {
				[aria-label]:after {
					display: inline-block;
					opacity: 1;
				}
			}
		}
	}
	&__link {
		color: $color--dark;
		display: block;
		font-size: $font-s;
		padding: $space-none;
		text-decoration: none;		
		transition: all 1s ease;
		&:hover {
			text-decoration: none;
			cursor:url(/images/circle.svg),auto;
		}
		&:focus {
			outline: 1px dotted $color--dark;
		}
		&--active {
			@extend .globalNav__link;
		}		
	}
}