/* ~~~~~~~~~~~~~~~~~~~~~
Theme typography
~~~~~~~~~~~~~~~~~~~~~ */
h1 {
	font-size: $font-l;
	font-weight: 300;
	line-height: 1.25;
	margin: $space-none $space-none $space-xs $space-none;
	padding: $space-none;
}

h2 {
	font-size: $font-m;
	font-weight: 300;
	line-height: 1.25;
	margin: $space-none $space-none $space-xs $space-none;
	padding: $space-none;
}

h3 {
	font-size: $font-s;
	font-weight: 300;
	line-height: 1.5;
	letter-spacing: 1px;
	margin: $space-m $space-none;
	padding: $space-none;
	text-transform: uppercase;
}

p {
	font-size: $font-m;
	font-weight: normal;
	line-height: 1.5;
	margin: $space-none $space-none $space-l $space-none;
	padding: $space-none;
	a {
		color: $color--light;
		text-decoration: none;
		.icon--external-link {
			display: inline-block;
			vertical-align: super;
			margin-top: -100px;
		}
		&:hover {
			text-decoration: none;
			cursor: url(/images/circle.svg),auto;
			color: $color--primary;
			opacity: 0.5;
		}
		
	}
	
}
address { margin: $space-none; }

b,
strong {
	font-weight: normal;
	font-family: 'FolioMedium',-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
}

small {
	font-size: $font-s;
	line-height: 1.25;
}

em {	// font-variation-settings: "wght" 300;
	font-style: italic;
	font-family: 'FolioLightItalic',-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
}

code {
	background-color: $color--dark;
	font-size: $font-s;
	color: $color--light;
	padding: $space-none;
	display: block;
	line-height: 2;
	margin: $space-none;
	box-shadow:
		1px 1px 0 0 $color--light,
		4px 4px 0 0 $color--dark;
}