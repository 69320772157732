/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Theme variables
~~~~~~~~~~~~~~~~~~~~~~~~~ */
  // $font--primary:     -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  $font--primary:     'FolioLight',-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  
  $font-s:            rem(12);
  $font-m:            rem(16);
  $font-l:            rem(20);
  $font-xl:            rem(40);
  
  $space-none:        0;
  $space-xs:          rem(4);
  $space-s:           rem(8);
  $space-m:           rem(16);
  $space-l:           rem(24);
  $space-xl:          rem(32);
  
  $color--dark:       #132f28;
  $color--light:      hsla(83, 8%, 84%, 1);
  $color--lighter:    #ffffff;
  $color--primary:    hsla(47, 95%, 39%, 1);
    
  $breakpoints: (
   'small':           (min-width: 500px),
   'medium':          (min-width: 800px),
   'large':           (min-width: 1000px),
   'huge':            (min-width: 1200px),
  );

  $pageWidth:         rem(1024);
