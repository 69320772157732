/*  ==========================================================================
    General
    ========================================================================== */
    *, *:before, *:after {
      box-sizing: inherit;
    }

    html {
      height: 100%;
      box-sizing: border-box;
    }
    body {
      background-color: $color--dark;
      color: $color--lighter;
      font-family: $font--primary;
      font-size: $font-m;
      min-height: 100%;
      margin: $space-none auto;
      padding: 0;
      width: 100%;
      max-width: rem(750);

      text-rendering: optimizeLegibility;      
      // @media (prefers-color-scheme: dark) {
      //   background: $color--dark;
      //   color: $color--light;
      // }
      &.notas {
          background-color: $color--light;
      }
    }

    .pageLayout {
      display: grid;
      grid-template-columns: 1fr;
      align-content: start;
    }
    @keyframes fade {
      0% { opacity: 0;}
      100% {opacity: 1;}
    }

/*  ==========================================================================
    Images
    ========================================================================== */
    
    img {
      display: block;
      height: auto;
      max-width: 100%;
    }

    figure {
      margin: 0;
    }

    a {
      color: $color--light;
      text-decoration: none;
      &:visited {
       color: $color--light;
      }
      &:hover,
      &:active {
        cursor: url(/images/circle.svg),auto;
		color: $color--primary;
        text-decoration: none;
      }
      .notas & {
        color: $color--dark;  
      }
    }
    
    