/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Notes list
~~~~~~~~~~~~~~~~~~~~~~~~~ */

.notes {
  margin: rem(128) $space-none;
  color: $color--light;
}

.notesArticle {
    margin: $space-none $space-none $space-m $space-none;
    padding: $space-none;
    display: grid;
    grid-template-columns: auto 2fr;
    align-content: start;
    gap: 0;
    @include respond-to('small') {
      grid-template-columns: auto 2fr auto;
    }
    &.recent__note {
      padding: $space-none;
      & .notesArticle__title {
        font-size: $font-l;
      }
      & .notesArticle__link {
        grid-column: 2 / -1;
        @include respond-to('medium') {
          grid-column: 2 / 3;
        }
      }
      & .notesArticle__time {
        grid-column: 2 / 3;
        @include respond-to('medium') {
          grid-column: 3 / -1;
        }
      }
      & .notesArticle__number {
        color: $color--light;
      }
     
    }
    &:last-child {}

    &__number {
      font-size: $font-s;
      text-transform: uppercase;
      line-height: 1.65;
      padding: $space-none;
      margin: $space-none $space-s $space-none $space-none;
      @include respond-to('medium') {
        margin: $space-none $space-none $space-none rem(-28);
      }
    }
    &__time {
      color: $color--light;
      font-size: $font-s;
      grid-column: 2/3;
      margin: $space-none $space-none $space-s $space-none;
      @include respond-to('medium') {
        grid-column: 3/4;
      }
    }
    &__title {
      font-size: $font-m;
      text-decoration: none;
      display: inline-block;
      padding: $space-none;
    }
    &__link {
      text-decoration: none;
      display: inline-block;
    }
    &__legend {
      font-size: $font-m;
      grid-column: 2 / 3;
      padding-right: $space-xl;
      @include respond-to('medium') {
        -webkit-line-clamp: 3;
      }
    }
  }

/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Notes single
~~~~~~~~~~~~~~~~~~~~~~~~~ */
.page .mainNotas {
  display: grid;
  grid-template-columns: 1fr;
  margin: $space-none auto;
  padding: $space-none;
  color: $color--dark;
} 
.notesNav {
  display: flex;
  flex-flow: column wrap;
    margin: $space-none $space-none $space-xl $space-none;
    padding: $space-none $space-m;
    min-height: rem(48);
    @include respond-to("medium"){
      align-items: baseline;
      flex-flow: row wrap;
    }
    &__list {
    &::-webkit-scrollbar {
      display: none;
    }
        overflow-x: scroll;
        overflow-y: hidden;
        margin: $space-none;
        list-style: none;
        padding: $space-none;
        display: inline-flex;
        flex-flow: row nowrap;
        gap: $space-none $space-s;
        @include respond-to("medium"){
        padding: $space-none $space-m;
          
        }
        li {
          flex: 0 0 auto;
        }
    }
    .post_id {
      margin-right: $space-xs;
    }
    &__link {
        font-size: $font-s;
        color: $color--dark;
        display: inline-flex;
        flex-flow: row nowrap;
        margin: 0;
        text-decoration: none;
        padding: rem(2) $space-s;
        transition: padding 1s ease;
        background-color: rgba($color--dark,0.1);
        border-radius: 6px;
//         &::after {
//           content: "➞";
//           padding: 0 $space-s 0 0.5rem;
//           transition: padding 0.7s ease;
//           margin-left: auto;
// 
//         }
        &:hover {
          text-decoration: none;
          background-color: rgba($color--lighter,0.1);
          &::after {
            padding: 0 $space-xs 0 1rem;
          }
        }
        .post_id {
        }
    }
}
.singleArticle {
  margin: 0 auto;
  padding: $space-none $space-m;
  @include respond-to("medium"){}
    &__title {
      font-size: $font-xl;
    }
    &__number {
      display: inline-block;
      font-size: $font-s;
      padding: $space-none;
      margin: $space-none $space-s $space-none $space-xs;
    }
    &__info {
      font-size: $font-s;
    }
    &__time {}
    &__reading {}
    &__wordcount {}
    &__excerpt {
      margin: $space-m $space-none;
      p {
        font-size: $font-l;
      }
    }
    &__content {}
}